import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CustomerConfig } from '@models/customer-config';
import { ApiUrlPipe } from '@pipes/api-url.pipe';
import { filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private router: Router,
    private apiUrlPipe: ApiUrlPipe,
    private title: Title,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        tap((route: ActivatedRoute) => {
          const content = document.querySelector('.mat-sidenav-content');
          const state = this.router.getCurrentNavigation().extras.state;
          if (content && !state?.noScroll) {
            content.scrollTop = 0;
          }
          this.initSeo(route.snapshot.data.customerConfig);
        }),
      )
      .subscribe();
  }

  initSeo(customerConfig: CustomerConfig): void {
    if (customerConfig && customerConfig.title) {
      this.title.setTitle(customerConfig.title);
    }

    if (customerConfig && customerConfig.favicon) {
      const favIcon: HTMLLinkElement = document.querySelector('#favicon');
      favIcon.href = this.apiUrlPipe.transform(customerConfig.favicon?.url);
      favIcon.type = customerConfig.favicon?.mimeType;
    }
  }
}
