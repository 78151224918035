import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/** MODULES */
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
/** COMPONENTS */
import { AppComponent } from './app.component';
/** ENVIRONMENTS */
import { environment } from '../environments/environment';
/** INTERCEPTORS */
import { OauthInterceptor } from './interceptors/oauth.interceptor';
/** MODELS */
import { modalOptions } from '@models/modalOptions';
/** SERVICES */
import { FormService } from '@services/form.service';
import { OauthService } from '@services/oauth.service';
import { TokenCookieStorageService } from '@services/token-cookie-storage.service';
/** PIPES */
import { CodolenceStatePipe } from '@pipes/codolence-state.pipe';
import { CondolencePipe } from '@pipes/condolence.pipe';
import { PrefixPipe } from '@pipes/prefix.pipe';
import { ShopBooleanPipe } from '@pipes/shop-boolean.pipe';
/** ANGULAR MATERIAL */
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
/** HELPERS */
/** INTERNATIONALIZATION */
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ProfilesResolver } from '@resolvers/profiles.resolver';
import { GlobalErrorHandler } from '@utils/global-error-handler';
import { MatPaginatorIntlFr } from './internationalization/angular-material-paginator';

registerLocaleData(localeFr);

@NgModule({
  declarations: [AppComponent, PrefixPipe, ShopBooleanPipe, CodolenceStatePipe, CondolencePipe],
  exports: [BrowserAnimationsModule, BrowserModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CookieModule.forRoot({
      path: '/',
      domain: environment.cookieDomain,
    }),
    GoogleMapsModule,
  ],
  providers: [
    OauthService,
    TokenCookieStorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OauthInterceptor,
      multi: true,
    },
    CurrencyPipe,
    PrefixPipe,
    FormService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: modalOptions },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    ShopBooleanPipe,
    CodolenceStatePipe,
    CondolencePipe,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlFr,
    },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    ProfilesResolver,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ],
})
export class AppModule {}
