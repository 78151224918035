import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ConfirmModalData } from '@models/confirm-modal-data';
import { interval } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-update-reload-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './update-reload-dialog.component.html',
  styleUrls: ['./update-reload-dialog.component.scss'],
})
export class UpdateReloadDialogComponent {
  delay = 10;
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmModalData) {
    const sub = interval(1000)
      .pipe(
        tap(() => {
          this.delay--;
          if (this.delay <= 0) {
            window.location.reload();
            if (sub && !sub.closed) {
              sub.unsubscribe();
            }
          }
        }),
      )
      .subscribe();
  }
}
