import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { DeathNotice } from '@models/death-notice.data-list';
import { BaseService } from '@services/base.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MenuConfigResolver {
  private endpoint = '/admin/configuration/menu';

  constructor(
    private readonly service: BaseService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DeathNotice | null> {
    let url;
    if (route.params.profileId) {
      url = `${this.endpoint}/${route.params.profileId}`;
    } else {
      url = this.endpoint;
    }
    return this.service.get(url).pipe(
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return of(null);
      }),
    );
  }
}
