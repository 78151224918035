import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shopBoolean',
})
export class ShopBooleanPipe implements PipeTransform {
  transform(value: any): any {
    const icon = value === true ? 'active' : 'deleted';
    return (
      '<span><i class="icon ' + icon + '"></i><span>' + (value === true ? 'Active' : 'Inactive') + '</span></span>'
    );
  }
}
