<h1 mat-dialog-title [style.--mat-dialog-title]="data?.customerConfig?.style?.headings">Mise à jour disponible</h1>
<div mat-dialog-content>
  <p>Une nouvelle mise à jour est disponible, le site va se recharger automatiquement dans {{ delay }}s</p>
</div>
<div class="row border-top" mat-dialog-actions>
  <div class="col col-12">
    <button
      class="mx-1 w-100"
      color="accent"
      mat-dialog-close="true"
      mat-raised-button
      type="button"
      [style.--button-primary-background]="data?.customerConfig?.style?.modalSaveButton.background_color"
      [style.--button-primary-color]="data?.customerConfig?.style?.modalSaveButton.color"
    >
      Recharger la page manuellement
    </button>
  </div>
</div>
