import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/** RESOLVER */
import { MlayoutComponent } from '@components/mlayout/mlayout.component';
import { MlayoutModule } from '@components/mlayout/mlayout.module';
import { CustomerConfigResolver } from '@resolvers/customer-config.resolver';
import { MenuConfigResolver } from '@resolvers/menu-config.resolver';
import { ProfileConfigResolver } from '@resolvers/profile-config.resolver';
import { ProfileIntegrationsResolver } from '@resolvers/profile-integrations.resolver';
import { ProfileServicesResolver } from '@resolvers/profile-services.resolver';
import { ProfileResolver } from '@resolvers/profile.resolver';
import { ProfilesResolver } from '@resolvers/profiles.resolver';

const subRoutes: Routes = [
  {
    path: 'order',
    loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule),
  },
  {
    path: 'in-their-memory',
    loadChildren: () => import('./pages/order/in-their-memory/in-their-memory.module').then(m => m.InTheirMemoryModule),
  },
  {
    path: 'udiana',
    loadChildren: () => import('./pages/order/udiana/udiana.module').then(m => m.UdianaModule),
  },
  {
    path: 'grafe',
    loadChildren: () => import('./pages/order/grafe/grafe.module').then(m => m.GrafeModule),
  },
  {
    path: 'tribute-book',
    loadChildren: () => import('./pages/order/tribute-book/tribute-book.module')
      .then(m => m.TributeBookModule)
  },
  {
    path: 'estimate',
    loadChildren: () => import('./pages/estimate/routes').then(mod => mod.ESTIMATE_ROUTES),
  },
  {
    path: 'enquiry',
    loadChildren: () => import('./pages/enquiry/routes').then(mod => mod.ENQUIRY_ROUTES),
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call/routes').then(mod => mod.CALL_ROUTES),
  },
  {
    path: 'invoice',
    loadChildren: () => import('./pages/invoice/invoice.module').then(m => m.InvoiceModule),
  },
  {
    path: 'family-space-v2',
    loadChildren: () => import('./pages/family-space-v2/family-space-v2.module').then(m => m.FamilySpaceV2Module),
  },
  {
    path: 'reviews',
    loadChildren: () => import('./pages/reviews/reviews.module').then(m => m.ReviewsModule),
  },
];

const routes: Routes = [
  {
    path: 'signin',
    resolve: {
      customerConfig: CustomerConfigResolver,
    },
    loadChildren: () => import('./pages/signin/signin.module').then(module => module.SigninModule),
  },
  {
    path: 'request-password',
    resolve: {
      customerConfig: CustomerConfigResolver,
    },
    loadChildren: () => import('./pages/request-password/request-password.module').then(m => m.RequestPasswordModule),
  },
  {
    path: 'password-update',
    resolve: {
      customerConfig: CustomerConfigResolver,
    },
    loadChildren: () => import('./pages/password-update/password-update.module').then(m => m.PasswordUpdateModule),
  },
  {
    path: 'unauthorized',
    resolve: {
      customerConfig: CustomerConfigResolver,
      profiles: ProfilesResolver,
    },
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
  },
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MlayoutComponent,
    resolve: {
      customerConfig: CustomerConfigResolver,
      profiles: ProfilesResolver,
      menuConfig: MenuConfigResolver,
    },
    children: [
      {
        path: 'information',
        pathMatch: 'full',
        loadChildren: () => import('./pages/information/information.module').then(m => m.InformationModule),
      },
      {
        path: 'shop',
        pathMatch: 'prefix',
        loadChildren: () => import('@pages/shop/shop.routes').then(c => c.shopRoutes),
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
      },
      {
        path: 'place',
        loadChildren: () => import('./pages/place/place.module').then(m => m.PlaceModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'customer',
        loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule),
      },
      ...subRoutes,
      {
        path: 'computed-stats',
        loadChildren: () => import('./pages/computed-stats/routes').then(mod => mod.COMPUTED_STATS_ROUTES),
      },
      {
        path: ':profileId',
        resolve: {
          profile: ProfileResolver,
          profileConfig: ProfileConfigResolver,
          profileIntegrations: ProfileIntegrationsResolver,
          menuConfig: MenuConfigResolver,
        },
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: 'home',
            loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
          },
          ...subRoutes,
          {
            path: 'stats',
            loadChildren: () => import('./pages/stats/routes').then(mod => mod.STATS_ROUTES),
            resolve: {
              services: ProfileServicesResolver,
            },
          },
          {
            path: 'serenite-obseques',
            loadChildren: () => import('@pages/catalog/routes').then(mod => mod.CATALOGUE_ROUTES),
          },
          {
            path: 'setting',
            loadChildren: () => import('./pages/profile/profile-add/profile-add.module').then(m => m.ProfileAddModule),
          },
          {
            path: 'establishments',
            loadChildren: () =>
              import('./pages/establishments/establishments.module').then(m => m.EstablishmentsModule),
          },
          {
            path: 'information',
            pathMatch: 'full',
            loadChildren: () => import('./pages/information/information.module').then(m => m.InformationModule),
          },
          {
            path: 'external-services',
            loadChildren: () =>
              import('./pages/external-services/external-services.module').then(m => m.ExternalServicesModule),
          },
          {
            path: 'business-tools',
            loadChildren: () => import('./pages/business-tools/business-tools.module').then(m => m.BusinessToolsModule),
          },
          {
            path: 'customize',
            loadChildren: () => import('./pages/customize/customize.module').then(m => m.CustomizeModule),
          },
          {
            path: 'preferences',
            loadChildren: () => import('@pages/profile-preferences/routes').then(mod => mod.PROFILE_PREFERENCES_ROUTES),
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'home',
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/unauthorized',
  },
];

@NgModule({
  imports: [
    MlayoutModule,
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [ProfileResolver, ProfileConfigResolver],
})
export class AppRoutingModule {}
